import FF from "./FormField.module.css";

import { notNull } from "@warrenio/utils/notNull";
import { createContext, useContext, useState, type ReactElement, type ReactNode } from "react";
import { useFocusWithin } from "react-aria";
import { first } from "remeda";
import { mcn, type BaseProps } from "../../utils/baseProps.ts";
import { cn } from "../../utils/classNames.ts";
import { MaskIcon } from "../icon/MaskIcon.tsx";

export interface TileItem<T> {
    key: string;
    value: T;
    label: ReactNode;
    disabled?: boolean;
}

export interface TileProps<T> extends BaseProps {
    icon?: (isSelected: boolean) => ReactNode;
    title?: ReactNode;
    /** Can also pass completely custom children, if required */
    children?: ReactNode;

    optionHeader?: ReactNode;

    items: TileItem<T>[];
    defaultValue?: T;

    /** Remember the last selected value even if the icon is not currently selected */
    rememberLastSelected?: boolean;
}

export interface TileValueProps<T> {
    value: T | undefined;
    equal: (a: T, b: T) => boolean;

    onChange?: (value: T) => void;
}

export interface TileSelectProps<T> extends TileValueProps<T> {
    children: ReactNode | ReactElement<typeof Tile<T>>[];
}

const TileSelectContext = createContext<TileValueProps<any> | null>(null);

/** Special value to represent that nothing has been selected (NB: no option should use this value) */
const UNSELECTED = "__unselected__";

export function Tile<T>({
    icon,
    title,
    children,
    optionHeader,
    items,
    defaultValue,
    rememberLastSelected = true,
    ...props
}: TileProps<T>) {
    //#region Hooks
    const { equal, onChange, value } = notNull(
        useContext(TileSelectContext),
        "Tile must be used within TileSelect",
    ) as TileValueProps<T>;

    const [isFocusWithin, setFocusWithin] = useState(false);
    const { focusWithinProps } = useFocusWithin({
        onFocusWithinChange: (isFocusWithin) => setFocusWithin(isFocusWithin),
    });

    /** The item that is selected, if it is contained in this tile */
    const selectedItem = value != null ? items.find((i) => equal(i.value, value)) : undefined;

    // Remember last selected value (even if the tile itself is not selected)
    const [savedValue, setSavedValue] = useState<T | undefined>(
        // If we start off as selected, remember that value
        selectedItem?.value,
    );

    function changeValue(value: T) {
        setSavedValue(value);
        onChange?.(value);
    }

    //#endregion

    const enabledItems = items.filter((i) => !i.disabled);

    if (defaultValue === undefined && enabledItems.length > 0) {
        defaultValue = enabledItems[0].value;
    }

    /** Item that is displayed as selected in the dropdown (not necessarily actually selected) */
    const displayedItem =
        selectedItem ??
        (rememberLastSelected && savedValue !== undefined
            ? // NB: Selected item can be disabled
              items.find((i) => equal(i.value, savedValue))
            : optionHeader !== undefined
              ? undefined
              : // Just pick arbitrary item to display if nothing is selected or all items are disabled and there's no header
                (first(enabledItems) ?? first(items)));

    const isSelected = selectedItem !== undefined;
    const isDisabled = enabledItems.length === 0;
    return (
        <div
            {...focusWithinProps}
            {...mcn(cn(FF.FormFieldIconRadio, FF.withSelect), props)}
            onClick={(e) => {
                e.stopPropagation();
                if (isDisabled) {
                    return;
                }

                const newValue = displayedItem !== undefined ? displayedItem.value : defaultValue;
                if (newValue !== undefined) {
                    changeValue(newValue);
                }
            }}
            data-selected={isSelected ? true : undefined}
            data-disabled={isDisabled ? true : undefined}
            data-focused={isFocusWithin ? true : undefined}
        >
            {icon?.(isSelected)}
            {!!title && <div className={FF.title}>{title}</div>}
            {children}
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: TODO */}
            <div
                className={FF.Select}
                onClick={(e) => {
                    // NB: Prevent event from bubbling up to the parent div which would cause it to select the default value.
                    e.stopPropagation();
                }}
            >
                <select
                    aria-label={typeof optionHeader === "string" ? optionHeader : undefined}
                    value={displayedItem?.key ?? UNSELECTED}
                    onChange={(e) => {
                        const key = e.target.value;
                        const { value } = notNull(items.find((i) => i.key === key));
                        changeValue(value);
                    }}
                >
                    {optionHeader !== undefined && (
                        <option value={UNSELECTED} disabled>
                            {optionHeader}
                        </option>
                    )}
                    {items.map((v) => (
                        <option value={v.key} key={v.key} disabled={v.disabled}>
                            {v.label}
                        </option>
                    ))}
                </select>
                <MaskIcon className="jp-icon-caretdown size-1rem" />
            </div>
        </div>
    );
}

export function TileSelect<T>({ children, ...props }: TileSelectProps<T>) {
    // The select component itself does not have any DOM representation, the actual elements are rendered by the `Tile` components, so this is just an empty wrapper
    return <TileSelectContext.Provider value={props}>{children}</TileSelectContext.Provider>;
}
